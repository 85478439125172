const overlayTemplate = document.createElement("template")
overlayTemplate.innerHTML = `
    <div class="overlay">
        <a href="" class="close" title="close gallery">x</a>
        <a href="" class="switch prev" title="previous image">←</a>
        <figure>
            <img src="" title="">
            <figcaption>
                <p>...</p>
            </figcaption>
        </figure>
        <a href="" class="switch next" title="next image">→</a>
    </div>
`

class Gallery extends HTMLElement {
    _items
    _overlay
    _prevButton
    _nextButton
    _closeButton
    _currentIndex

    constructor() {
        super();
    }

    connectedCallback() {
        this._items = this.getElementsByTagName("article")
        this._drawOverlay()

        Array.from(this._items).forEach((item, i) => item.querySelector("header img").addEventListener("click", event => {
            this._currentIndex = i
            this._showImageInOverlay(item)
        }))
    }

    _drawOverlay() {
        const overlay = overlayTemplate.content.cloneNode(true)
        this._overlay = overlay.querySelector(".overlay")
        this._prevButton = overlay.querySelector(".prev")
        this._nextButton = overlay.querySelector(".next")
        this._closeButton = overlay.querySelector(".close")

        this._nextButton.addEventListener("click", event => {
            event.preventDefault()
            if (this._items.length === this._currentIndex + 1) {
                this._currentIndex = 0
            } else {
                this._currentIndex++
            }

            this._showImageInOverlay(this._items[this._currentIndex])
        })

        this._prevButton.addEventListener("click", event => {
            event.preventDefault()
            if (this._currentIndex === 0) {
                this._currentIndex = this._items.length - 1
            } else {
                this._currentIndex--
            }

            this._showImageInOverlay(this._items[this._currentIndex])
        })

        this._closeButton.addEventListener("click", event => {
            event.preventDefault()
            this._closeOverlay()
        })

        document.body.appendChild(this._overlay)
    }

    _showImageInOverlay(item) {
        this._overlay.classList.add("show")
        this._overlay.querySelector("img").src = item.querySelector("img").dataset.src
    }

    _closeOverlay() {
        this._overlay.classList.remove("show")
    }
}

customElements.define("js-gallery", Gallery)